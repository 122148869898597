import { ref, Ref } from "vue";
import { axiosClient, APP_BASE_URL } from "@/core/services/AxiosClient";
import { OrdersFilter, CustomersForLookUpFilter } from "@/models";
import {
  IGetOrderForViewDto,
  OrdersServiceProxy,
  CreateOrEditOrderDto,
  GetOrderForViewDto,
  IOrderCustomerLookupTableDto,
} from "@/shared/service-proxies/generated-proxies";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";

interface UsableOrders {
  orders: Ref<Array<IGetOrderForViewDto>>;
  customersForLookUp: Ref<Array<IOrderCustomerLookupTableDto>>;
  orderHistory: Ref<Array<GetOrderForViewDto>>;
  order: Ref<IGetOrderForViewDto | null>;
  getOrders: (filters: OrdersFilter) => void;
  getCustomersForLookUp: (filters: CustomersForLookUpFilter) => void;
  getOrderHistory: (id: string) => void;
  getOrder: (id: string) => void;
  deleteOrder: (id: string) => Promise<boolean>;
  updateOrCreateOrder: (order: IGetOrderForViewDto) => Promise<boolean>;
}

const useOrders = (): UsableOrders => {
  const store = useStore();

  const client = new OrdersServiceProxy(APP_BASE_URL, axiosClient);

  const orders: Ref<Array<IGetOrderForViewDto>> = ref([]);

  const customersForLookUp: Ref<Array<IOrderCustomerLookupTableDto>> = ref([]);

  const orderHistory: Ref<Array<GetOrderForViewDto>> = ref([]);

  const order: Ref<IGetOrderForViewDto | null> = ref(null);

  const getOrders = async (filters: OrdersFilter) => {
    const {
      ValidationStatusFilter,
      MaxOrderTotalPriceFilter,
      MinOrderTotalPriceFilter,
      MaxOrderDateFilter,
      MinOrderDateFilter,
      OrderStatusFilter,
      OrderPaymentStatusFilter,
      OrderPaymentMethodFilter,
      MaxOrderTotalQuantityFilter,
      MinOrderTotalQuantityFilter,
      OrderNumberFilter,
      CustomerURNOFilter,
      CustomerPriceListFilter,
      OrderChannelFilter,
      Sorting,
      SkipCount,
      MaxResultCount,
    } = filters;

    await client
      .getAll(
        ValidationStatusFilter,
        MaxOrderTotalPriceFilter,
        MinOrderTotalPriceFilter,
        MaxOrderDateFilter,
        MinOrderDateFilter,
        OrderStatusFilter,
        OrderPaymentStatusFilter,
        OrderPaymentMethodFilter,
        MaxOrderTotalQuantityFilter,
        MinOrderTotalQuantityFilter,
        OrderNumberFilter,
        CustomerURNOFilter,
        CustomerPriceListFilter,
        OrderChannelFilter,
        Sorting,
        SkipCount,
        MaxResultCount
      )
      .then((data) => {
        orders.value = data.items as unknown as Array<IGetOrderForViewDto>;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
      });
  };

  const getCustomersForLookUp = async (filters: CustomersForLookUpFilter) => {
    const { filter, sorting, skipCount, maxResultCount, cancelToken } = filters;

    await client
      .getAllCustomerForLookupTable(
        filter,
        sorting,
        skipCount,
        maxResultCount,
        cancelToken
      )
      .then((data) => {
        const { items } = data;
        customersForLookUp.value =
          items as unknown as IOrderCustomerLookupTableDto[];
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
      });
  };

  const getOrder = async (id: string) => {
    await client
      .getOrderForView(id)
      .then((data) => {
        order.value = data;
        // console.log(data);
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
      });
  };

  const updateOrCreateOrder = async (order: IGetOrderForViewDto) => {
    const payload = {
      orderChannel: order.orderChannel,
      orderTotalPrice: order.order.orderTotalPrice,
      orderDate: order.order.orderDate,
      orderNumber: order.order.orderNumber,
      orderPaymentMethod: order.order.orderPaymentMethod,
      orderStatus: order.order.orderStatus,
      orderPaymentStatus: order.order.orderPaymentStatus,
      orderTotalQuantity: order.order.orderTotalQuantity,
      agentId: order.order.agentId,
      customerId: order.order.customerId,
      orderDetails: order.orderDetails?.map((order) => order.orderDetail),
      id: order.order.id,
    } as unknown as CreateOrEditOrderDto;

    const resp = await client
      .createOrEdit(payload)
      .then(() => {
        return true;
      })
      .catch((e) => {
        store.commit(Mutations.SET_ERROR, e);
        return false;
      });

    return resp;
  };

  const getOrderHistory = async (id: string) => {
    await client
      .getHistory(id)
      .then((data) => {
        orderHistory.value = data;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
      });
  };

  const deleteOrder = async (id: string): Promise<boolean> => {
    const resp = await client
      .delete(id)
      .then(() => true)
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
        return false;
      });

    return resp;
  };

  return {
    orderHistory,
    customersForLookUp,
    orders,
    order,
    getOrder,
    getOrders,
    deleteOrder,
    updateOrCreateOrder,
    getOrderHistory,
    getCustomersForLookUp,
  };
};

export default useOrders;
