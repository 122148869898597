import Swal from "sweetalert2";
import { useToast } from "vue-toast-notification";
export const toast = useToast();

export default {
  delete: async (
    id: string,
    deleteModel: (arg0: string) => Promise<boolean>
  ) => {
    let success = false;
    await Swal.fire({
      title: "Are you sure!",
      text: "",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
    }).then(async (willDelete) => {
      if (willDelete.isConfirmed) {
        const res = await deleteModel(id);
        if (res) {
          success = true;
          toast.success("Deleted successfully");
        } else {
          toast.warning("An error has occurred, item not deleted!");
        }
      }
    });

    return success;
  },
};
