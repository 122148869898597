
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { IGetOrderForViewDto } from "@/shared/service-proxies/generated-proxies";
import { OrdersFilter } from "@/models";
import moment from "moment";
import AJLoader from "@/components/AjaxLoader.vue";
import useOrders from "@/composables/useOrders";
import DeleteItemService from "@/core/services/DeleteItemService";
import { useStore } from "vuex";
import usePriceList from "@/composables/usePriceList";
import { PriceListFilter } from "@/models";
import { Channel } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "Orders",
  components: {
    Datatable,
    AJLoader,
  },
  setup() {
    const { orders, getOrders, deleteOrder } = useOrders();
    const checkedOrders = ref([]);

    const breadcrumbs = {
      title: "Orders",
      breadcrumbs: ["Orders"],
    };

    const store = useStore();
    const config = store.getters.getConfigurations;
    const permissions = config.result.auth.grantedPermissions;

    let loading = ref<boolean>(true);
    const showAdvanceFilter = ref<boolean>(false);
    const { priceLists, getPriceLists } = usePriceList();

    const tableHeader = ref([
      {
        name: "Actions",
        key: "actions",
      },
      {
        name: "Order date",
        key: "orderDate",
        sortable: true,
      },
      {
        name: "Order number",
        key: "orderNumber",
        sortable: true,
      },
      {
        name: "Customer name",
        key: "customerName",
        sortable: true,
      },
      {
        name: "Total price",
        key: "orderTotalPrice",
        sortable: true,
      },
      {
        name: "Total quantity",
        key: "orderTotalQuantity",
        sortable: true,
      },
      {
        name: "Order channel",
        key: "orderChannel",
        sortable: true,
      },
      {
        name: "Order status",
        key: "orderStatus",
        sortable: true,
      },
      {
        name: "Is validated",
        key: "isValidated",
        sortable: true,
      },
      {
        name: "Payment method",
        key: "orderPaymentMethod",
        sortable: true,
      },
      {
        name: "Payment status",
        key: "orderPaymentStatus",
        sortable: true,
      },
    ]);

    const defaultFilter = ref({
      ValidationStatusFilter: -1,
      OrderStatusFilter: -1,
      OrderPaymentStatusFilter: -1,
      OrderPaymentMethodFilter: -1,
      OrderNumberFilter: undefined,
      SkipCount: 0,
      MaxResultCount: 2000,
      CustomerPriceListFilter: undefined,
      // OrderChannelFilter: "",
    } as unknown as OrdersFilter);

    const tableData = ref<Array<IGetOrderForViewDto>>([]);

    const initialData = ref<Array<IGetOrderForViewDto>>([]);

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Oders", ["Orders header info"]);
      await getPriceListData();
      await getData();
    });

    const deleteHandler = async (id: string) => {
      const res = await DeleteItemService.delete(id as string, deleteOrder);

      if (res) {
        for (let i = 0; i < tableData.value.length; i++) {
          if (tableData.value[i].order.id === id) {
            tableData.value.splice(i, 1);
          }
        }
      }
    };

    const getData = async () => {
      loading.value = true;

      await getOrders(defaultFilter.value as unknown as OrdersFilter);

      tableData.value = orders.value;

      initialData.value.splice(0, tableData.value.length, ...tableData.value);

      loading.value = false;
    };

    const defaultPriceListFilter = ref({
      sorting: null,
      skipCount: 0,
      maxResultCount: 1000,
    } as unknown as PriceListFilter);

    const getPriceListData = async () => {
      await getPriceLists(
        defaultPriceListFilter.value as unknown as PriceListFilter
      );
    };

    const searchFunc = async () => {
      await getData();
    };

    return {
      defaultFilter,
      loading,
      tableData,
      tableHeader,
      showAdvanceFilter,
      checkedOrders,
      deleteHandler,
      searchFunc,
      moment,
      breadcrumbs,
      permissions,
      priceLists,
      Channel,
    };
  },
});
